@import '../../../assets/variables';

.navigation-wrapper {
    a:hover {
        text-decoration: none;
    }

    li {
        box-sizing: border-box;
        display: block;
        padding: 10px 30px;
        border-left: 4px solid $primary-color;
        color: black;
        border-bottom: 1px solid $gray-300;

        &:hover {
            border-left-width: 10px;
            border-bottom-color: $primary-color;
            transition: all 0.2s ease;
        }

        &:not(:first-child) {
            border-top: 1px solid $primary-color;
        }

        &.active {
            color: $primary-color;
        }

        i {
            width: 40px;
            display: inline-block;
            font-size: 22px;
        }
    }
}
