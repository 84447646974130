@import './assets/main.scss';
@import 'bootstrap';
@import 'theme';
@import 'react-notifications-component/dist/theme.css';
@import 'react-image-crop/src/ReactCrop.scss';


.c-white {
    color: white !important;
}

.caret-4-desc::after {
    content: 'v';
    width: 10px;
    height: 10px;
    font-size: 12px;
    display: inline-block;
}

.caret-4-asc::after {
    content: '^';
    width: 10px;
    height: 10px;
    font-size: 12px;
    display: inline-block;
}
.react-bootstrap-table table {
    table-layout: auto;
}
