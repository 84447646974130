@import 'variables';
@import 'form';
@import 'lists';

// check bootstrap/sass/_variables.scss for all variables
$primary: orange !default;


.nav-item {
  .nav-link.active {
    background-color: $primary-color !important;
    color: white !important;
  }
}

h1 {
  text-align: center;
  background-color: #ffeddd;
  padding: 10px 10px;
}