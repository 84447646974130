@import '../../../assets/variables';

.header-wrapper {
    border-bottom: 1px solid $gray-300;

    .toggle-menu {
        padding: 0 18px;
        margin-right: 20px;
        border-right: 2px solid $gray-600;
        font-size: 25px;
        cursor: pointer;
    }

    .maitenance {
        margin-right: 20px;
    }
}
