.app-wrapper {
    .content-wrapper {
        position: relative;
    }

    .navigation-wrapper {
        position: absolute;
        left: 0;
        top: 0;
        width: 100px;
        bottom: 0;
        min-height: calc(100vh - 30px);
        background-color: #f9f9f9;
        z-index: 2;
        transition: all 0.4s;

        ul li span {
            display: none;
        }
    }

    &.menu-open {
        .navigation-wrapper {
            width: 200px;
            transition: all 0.4s;
        }

        ul li span {
            display: initial;
        }
        @media only screen and (max-width: 600px) {
            .navigation-wrapper {
                display: none;
            }
            ul li span {
                display: none;
            }
            .main-content {
                margin-left: 10px;
                padding: 5px 0px;
            }
        }
    }
    .main-content {
        background-color: white;
        margin-left: 100px;
        padding: 10px 30px;
    }
}
/* responsive design for listing
@media screen and (max-width: 640px) {
     
    .react-bootstrap-table table thead {
      display: none;
     
    }
    
    .react-bootstrap-table table tbody td {
      display: block;
      padding-left: 0 !important;
      text-align: center !important;
    }
    .react-bootstrap-table table tbody tr td:first-child {
      background: #666;
      color: #fff;
    }
    
    .react-bootstrap-table table tbody tr td:first-child:before {
      color: rgb(225, 181, 71);
    } 
    .react-bootstrap-table table tr th:last-child,
    .react-bootstrap-table table tr td:last-child {
      max-width: 100% !important;
      min-width: 100px !important;
      width: 100% !important;
    }
  }
  */
@media only screen and (max-width: 640px) {
    .app-wrapper {
        .main-content {
            margin-left: 80px;
            padding: 5px 0px;
        }
    }
    .navigation-wrapper {
        width: 75px !important;
        li {
            padding: 10px 20px;
        }
        svg {
            width: 1em !important;
        }
    }
}
