@import '../../../assets/variables';

.icon-wrapper {
  padding-left: 0;
  padding-right: 5px;

  i {
    cursor: pointer;

    &.unpublish-icon {
      color: red;
      margin-left: 5px;
    }

    &.invisible-icon {
      color: #6c757d;
    }
  }
}