.toggle-show-wrapper {
  position: relative;

  .toggle-element {
    cursor: pointer;
  }

  .icon-edit {
    position: absolute;
    top: 0;
    right: 0;
  }
}