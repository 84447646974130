@import "../../assets/variables.scss";

.table-wrapper {
    .pagination {
        justify-content: flex-end;
        width: 70%;
        float: right;
    }
    .centrer {
        border: 2px solid #ff9f1a;
        color: #ff9f1a;
        font-weight: bold;
        font-size: 1rem;
        padding: 10px;
    }
    .table td {
        padding: 0.66rem;
    }

    .actions {
        width: 50px;
        text-align: center;
    }

    .date {
        width: 80px;
    }

    .datetime {
        width: 165px;
        font-size: 0.9rem;
    }

    .bg-success {
        background-color: #54bca3 !important;
        color: white;
    }

    tr th {
        background-color: $primary-color;
        color: white;
        border: 1px solid black;
    }

    tr td, tr th {
        vertical-align: middle;
        text-align: center;
    }
}
