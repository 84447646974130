.login-page {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: top;
    background-image: url('background.png');

    h3 {
        color: var(--primary);
    }

    .form-wrapper {
        width: 400px;
        padding: 40px 20px;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 10px;
        border: 1px solid #cecece;
    }
}
