.home-wrapper {
  h2 {
    font-size: 1.7rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-top: 1rem;
    text-align: center;
    border-bottom: 1px solid var(--bs-primary);
    border-top: 1px solid var(--bs-primary);
  }
}


.leaderboard {
  margin: 0 auto;
  width: 685px;
  border-radius: 10px;
  box-shadow: 0 7px 30px rgba(62, 9, 11, .3);

  ul {
    padding-left: 0;

    li {
      position: relative;
      z-index: 1;
      font-size: 14px;
      counter-increment: leaderboard;
      padding: 18px 10px 18px 50px;
      backface-visibility: hidden;
      transform: translateZ(0) scale(1.0, 1.0);
      background: #ff9f1a;

      a {
        color: white;
        font-size: 19px;
        line-height: 1;
        margin-right: 8px;
      }

      span {
        background-color: white;
        border-radius: 15px;
        padding: 2px 6px;
      }

      mark {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 18px 10px 18px 50px;
        margin: 0;
        background: none;
        color: #fff;

        &::before, &::after {
          content: '';
          position: absolute;
          z-index: 1;
          bottom: -11px;
          left: -9px;
          border-top: 10px solid lighten(#ff9f1a, 10);
          border-left: 10px solid transparent;
          transition: all .1s ease-in-out;
          opacity: 0;
        }

        &::after {
          left: auto;
          right: -9px;
          border-left: none;
          border-right: 10px solid transparent;
        }
      }

      small {
        position: relative;
        z-index: 2;
        display: block;
        text-align: right;
      }

      &::after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: lighten(#ff9f1a, 10);
        box-shadow: 0 3px 0 rgba(0, 0, 0, .08);
        transition: all .3s ease-in-out;
        opacity: 0;
      }
    }

    li:hover {
      z-index: 2;
      overflow: visible;

      &::after {
        opacity: 1;
        transform: scaleX(1.06) scaleY(1.03);
      }

      & mark {
        &::before, &::after {
          opacity: 1;
          transition: all .35s ease-in-out;
        }
      }
    }

  }
}