.project-step-type {
    margin-top: 20px;
    .card {
        text-align: center;
        cursor: pointer;
    }

    .fields-wrapper {
        margin: 10px auto;
        width: 80%;
        text-align: center;
    }
}
