.edit-project-wrapper {
    .menu {
        display: block;
        width: 100%;
        padding-bottom: 0;

        ul li {
            display: inline-block;
            margin-right: 20px;
        }
    }
}

.title-fixed {
    top: 50px;
    right: 10px;
    z-index: 20000;
    background-color: var(--bs-danger);
    color: white;
    border-radius: 50px;
    padding: 10px 20px;
}

@media only screen and (max-width: 640px) {
    .title-fixed {
        top: auto;
        bottom: 5px;
        left: 5px;
        z-index: 20000;
        background-color: var(--bs-danger);
        padding: 10px 5px;
        width: 44%;
        font-size: 9pt;
    }

    .mx-2 {
        margin-right: 0.5rem !important;
        margin-top: 10px !important;
    }
}
