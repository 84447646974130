.helper-wrapper {
    i {
        margin-right: 5px;
    }
}

.multi-select {
    .ms__menu {
        z-index: 100;
    }
}

.ck-content.ck-editor__editable {
    p {
        margin-top: 0;
        margin-bottom: 1px;
    }

    strong {
        color: var(--primary);
    }
}

.image-editable-field {
    margin: 10px 0;
    min-height: 200px;
    border: 1px solid #e9ecef;
    text-align: center;

    .image-editor-wrapper {
        position: relative;
        .actions {
            position: absolute;
            top: 0;
            right: 0;

            i {
                margin-right: 5px;
                font-size: 22px;
                cursor: pointer;
                color: var(--primary);
            }
        }

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}
@media only screen and (max-width: 640px) {
    .image-editable-field {
        width: 360px !important;
        height: 250px !important;
        margin: 10px 0;
        min-height: 200px;
        border: 1px solid #e9ecef;
        text-align: center;

        .image-editor-wrapper {
            img {
                width: 360px !important;
                height: 250px !important;
            }
        }
    }
}
