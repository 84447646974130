.attach-project {
  color: var(--bs-green);

  .toggle-children {
    margin: 0 2px;
  }
}

.email {
  color: var(--bs-yellow);
}